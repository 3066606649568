import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import NavButtons from 'components/720p_Series_CGI_List/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import IntroCards from 'components/720p_Series_CGI_List/Software_Menu/softwareCGI';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='720p Serie CGI List :: Software Menu' dateChanged='2019-09-20' author='Mike Polinowski' tag='INSTAR IP Camera' description='720p Serie CGI List' image='/images/Search/AU_SearchThumb_CGICommands_720p.png' twitter='/images/Search/AU_SearchThumb_CGICommands_720p.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/720p_Serie_CGI_Befehle/Software_Menu/' locationFR='/fr/720p_Series_CGI_List/Software_Menu/' crumbLabel="Software" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <IntroCards mdxType="IntroCards" />
    {/* * <Link to="/720p_Series_CGI_List/Software_Menu/Backup-Restore/">Backup-Restore</Link>
     * <Link to="/720p_Series_CGI_List/Software_Menu/Language/">Language</Link>
     * <Link to="/720p_Series_CGI_List/Software_Menu/Reboot-Reset/">Reboot-Reset</Link>
     * <Link to="/720p_Series_CGI_List/Software_Menu/Update/">Update</Link> */}

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      